import React from 'react';

const NotFoundPage = () => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <h1>404</h1>
    <p>Page not found</p>
  </div>
);

export default NotFoundPage;
